define('ember-material-lite/components/-base-child-component', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;

  exports['default'] = Component.extend({
    childComponentClassName: null,
    classNameBindings: ['_childComponentClassString'],
    _childComponentClassStringDelimeter: '--',
    _childComponentClassString: computed('composableParent', 'childComponentClassName', function () {
      var cccn = this.get('childComponentClassName');
      if (!cccn) {
        return '';
      } else {
        var parentComponent = this.get('composableParent');
        return '' + parentComponent.get('_primaryClassNameString') + this.get('_childComponentClassStringDelimeter') + this.get('childComponentClassName');
      }
    })
  });
});