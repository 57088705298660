define('ember-material-lite/components/mdl-menu', ['exports', 'ember', 'ember-composability/mixins/parent-component-support', 'ember-material-lite/templates/components/mdl-menu', 'ember-new-computed'], function (exports, _ember, _emberComposabilityMixinsParentComponentSupport, _emberMaterialLiteTemplatesComponentsMdlMenu, _emberNewComputed) {
  'use strict';

  exports['default'] = _ember['default'].Component.extend(_emberComposabilityMixinsParentComponentSupport['default'], {
    layout: _emberMaterialLiteTemplatesComponentsMdlMenu['default'],
    _primaryClassNameString: 'mdl-menu',
    icon: 'more_vert',
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var mdlmenu = new window.MaterialMenu(this.$('ul')[0]);
      this.set('_mdlComponent', mdlmenu);
    },
    _buttonId: (0, _emberNewComputed['default'])('elementId', {
      get: function get() {
        return this.get('elementId') + '-button';
      }
    }),
    align: 'top-left',
    _alignClass: (0, _emberNewComputed['default'])('align', {
      get: function get() {
        return 'mdl-menu--' + this.get('align');
      }
    })
  });
});