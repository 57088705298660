define('ember-material-lite/components/-base-toggle-component', ['exports', 'ember', 'ember-material-lite/components/-base-toplevel-component', 'ember-material-lite/mixins/ripple-support', 'ember-material-lite/mixins/click-action-support', 'ember-material-lite/templates/components/mdl-checkbox', 'ember-new-computed'], function (exports, _ember, _emberMaterialLiteComponentsBaseToplevelComponent, _emberMaterialLiteMixinsRippleSupport, _emberMaterialLiteMixinsClickActionSupport, _emberMaterialLiteTemplatesComponentsMdlCheckbox, _emberNewComputed) {
  'use strict';

  var deprecate = _ember['default'].deprecate;
  var computed = _ember['default'].computed;

  exports['default'] = _emberMaterialLiteComponentsBaseToplevelComponent['default'].extend(_emberMaterialLiteMixinsRippleSupport['default'], _emberMaterialLiteMixinsClickActionSupport['default'], {
    tagName: 'label',
    layout: _emberMaterialLiteTemplatesComponentsMdlCheckbox['default'],
    value: false,
    label: (0, _emberNewComputed['default'])('text', {
      get: function get() {
        deprecate('The \'label\' property has been deprecated. Please use \'text\' instead', false);
        return this.get('text');
      },
      set: function set(key, val) {
        deprecate('The \'label\' property has been deprecated. Please use \'text\' instead', false);
        this.set('text');
        return val;
      }
    }),
    text: '',
    attributeBindings: ['_inputId:for'],
    _inputId: computed('elementId', function () {
      return this.get('elementId') + '-input';
    })
  });
});