define('ember-material-lite/components/mdl-progress', ['exports', 'ember', 'ember-material-lite/components/-base-toplevel-component', 'ember-material-lite/templates/components/mdl-progress', 'ember-new-computed'], function (exports, _ember, _emberMaterialLiteComponentsBaseToplevelComponent, _emberMaterialLiteTemplatesComponentsMdlProgress, _emberNewComputed) {
  'use strict';

  var empty = _ember['default'].computed.empty;

  exports['default'] = _emberMaterialLiteComponentsBaseToplevelComponent['default'].extend({
    primaryClassName: 'progress',
    classNameBindings: ['indeterminate:mdl-progress__indeterminate'],
    _progress: null,
    _buffer: null,
    _mdlComponent: null,

    indeterminate: empty('progress'),
    layout: _emberMaterialLiteTemplatesComponentsMdlProgress['default'],

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var mdlprogress = new window.MaterialProgress(this.get('element'));
      this.set('_mdlComponent', mdlprogress);
      this._updateProgress();
      this._updateBuffer();
    },

    _updateProgress: function _updateProgress() {
      var progress = this.get('progress');
      if (progress !== null) {
        this.get('_mdlComponent').setProgress(progress);
      }
    },
    _updateBuffer: function _updateBuffer() {
      var buffer = this.get('buffer');
      if (buffer !== null) {
        this.get('_mdlComponent').setBuffer(buffer);
      }
    },

    progress: (0, _emberNewComputed['default'])('_progress', {
      get: function get() {
        return this.get('_progress');
      },
      set: function set(key, newVal) {
        if (this.element) {
          this._updateProgress();
        }
        this.set('_progress', newVal);
        return newVal;
      }
    }),

    buffer: (0, _emberNewComputed['default'])('_buffer', {
      get: function get() {
        return this.get('_buffer');
      },
      set: function set(key, newVal) {
        if (this.element) {
          this._updateBuffer();
        }
        this.set('_buffer', newVal);
        return newVal;
      }
    })
  });
});