define('ember-material-lite/components/mdl-table', ['exports', 'ember-material-lite/components/-base-toplevel-component', 'ember-composability/mixins/parent-component-support', 'ember-material-lite/mixins/shadow-support', 'ember-material-lite/templates/components/mdl-table'], function (exports, _emberMaterialLiteComponentsBaseToplevelComponent, _emberComposabilityMixinsParentComponentSupport, _emberMaterialLiteMixinsShadowSupport, _emberMaterialLiteTemplatesComponentsMdlTable) {
  'use strict';

  exports['default'] = _emberMaterialLiteComponentsBaseToplevelComponent['default'].extend(_emberComposabilityMixinsParentComponentSupport['default'], _emberMaterialLiteMixinsShadowSupport['default'], {
    primaryClassName: 'data-table',
    tagName: 'table',
    composableChildrenDebounceTime: 1,
    layout: _emberMaterialLiteTemplatesComponentsMdlTable['default'],
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var mdltable = new window.MaterialDataTable(this.get('element'));
      this.set('_mdlComponent', mdltable);
    }
  });
});