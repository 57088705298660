define('ember-material-lite/components/-base-input-component', ['exports', 'ember', 'ember-material-lite/components/-base-toplevel-component'], function (exports, _ember, _emberMaterialLiteComponentsBaseToplevelComponent) {
  'use strict';

  var computed = _ember['default'].computed;
  var isPresent = _ember['default'].isPresent;
  var observer = _ember['default'].observer;
  var run = _ember['default'].run;

  exports['default'] = _emberMaterialLiteComponentsBaseToplevelComponent['default'].extend({
    primaryClassName: 'textfield',
    type: 'text',
    disabled: false,
    isLabelFloating: true,
    errorMessage: null,
    classNameBindings: ['isLabelFloating:mdl-textfield--floating-label'],
    // This should never change, so we deliberately
    //   declare no dependent property keys
    _inputId: computed(function () {
      return this.get('elementId') + '-input';
    }),
    beforeMdlInit: function beforeMdlInit() {},
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.beforeMdlInit();
      var mdlTextfield = new window.MaterialTextfield(this.get('element'));
      this.set('_mdlComponent', mdlTextfield);
    },
    _checkValidity: observer('errorMessage', function () {
      run.scheduleOnce('afterRender', this, this._setValidity);
    }),
    _setValidity: function _setValidity() {
      if (this.isDestroyed) {
        return;
      }

      var mdlComponent = this.get('_mdlComponent');
      var errorMessage = this.get('errorMessage');

      if (isPresent(errorMessage)) {
        mdlComponent.input_.setCustomValidity(errorMessage.toString());
      } else {
        mdlComponent.input_.setCustomValidity('');
      }
    }
  });
});