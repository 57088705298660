define('ember-material-lite/components/-base-toplevel-component', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var computed = _ember['default'].computed;

  exports['default'] = _ember['default'].Component.extend({
    primaryClassName: null,
    _jsClass: true,
    classNameBindings: ['_primaryClassNameString', '_jsClassNameString'],

    _primaryClassNameString: computed('primaryClassName', function () {
      return 'mdl-' + this.get('primaryClassName');
    }),

    _jsClassNameString: computed('primaryClassName', '_jsClass', function () {
      if (this.get('_jsClass')) {
        return 'mdl-js-' + this.get('primaryClassName');
      } else {
        return '';
      }
    })
  });
});