define("ember-simple-uuid/utils/uuid", ["exports"], function (exports) {
  "use strict";

  exports["default"] = uuid;

  function uuid() {
    var _window$uuid;

    return (_window$uuid = window.uuid).v4.apply(_window$uuid, arguments);
  }
});