define('ember-material-lite/mixins/ripple-support', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var computed = _ember['default'].computed;
  var observer = _ember['default'].observer;

  exports['default'] = _ember['default'].Mixin.create({
    hasRipples: true,
    _rippleClass: computed('_primaryClassNameString', function () {
      return this.get('_primaryClassNameString') + '__ripple-container';
    }),
    classNameBindings: ['hasRipples:mdl-js-ripple-effect'],

    _mdlComponentObserver: observer('_mdlComponent', function () {
      if (this.get('hasRipples')) {
        var mdlripples = new window.MaterialRipple(this.$('.' + this.get('_rippleClass'))[0]);
        this.set('_mdlRippleComponent', mdlripples);
      }
    })
  });
});