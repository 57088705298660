define('liquid-fire-tweenlite/transitions/scroll-then', ['exports', 'ember', 'liquid-fire/is-browser', 'liquid-fire-tweenlite/lib/scroll-to'], function (exports, _ember, _liquidFireIsBrowser, _liquidFireTweenliteLibScrollTo) {
  'use strict';

  exports['default'] = function (nextTransitionName, options) {
    for (var _len = arguments.length, rest = Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
      rest[_key - 2] = arguments[_key];
    }

    var _this = this;

    if ((0, _liquidFireIsBrowser['default'])()) {
      var _ret = (function () {
        _ember['default'].assert("You must provide a transition name as the first argument to scrollThen. Example: this.use('scrollThen', 'toLeft')", 'string' === typeof nextTransitionName);

        var nextTransition = _this.lookup(nextTransitionName);
        if (!options) {
          options = {};
        }

        _ember['default'].assert("The second argument to scrollThen is passed to Velocity's scroll function and must be an object", 'object' === typeof options);

        // set scroll options via: this.use('scrollThen', 'ToLeft', {easing: 'spring'})
        options = _ember['default'].merge({ duration: 350 }, options);
        var offset = options.offset || 0;
        delete options.offset;

        // additional args can be passed through after the scroll options object
        // like so: this.use('scrollThen', 'moveOver', {duration: 100}, 'x', -1);

        return {
          v: (0, _liquidFireTweenliteLibScrollTo.scrollToY)(document.body, offset, options).then(function () {
            return nextTransition.apply(_this, rest);
          })
        };
      })();

      if (typeof _ret === 'object') return _ret.v;
    }
  };
});