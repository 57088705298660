define('liquid-fire-tweenlite/transitions/fade', ['exports', 'liquid-fire-tweenlite', 'ember'], function (exports, _liquidFireTweenlite, _ember) {
  'use strict';

  exports['default'] = fade;

  var copy = _ember['default'].copy;

  function fade() {
    var _this = this;

    var opts = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];

    var animateOut = undefined;
    var animateIn = undefined;
    var finishPrevious = undefined;
    var fadingElement = findFadingElement(this);
    var outOpts = copy(opts, true);
    opts = copy(opts, true);

    if (outOpts.duration) {
      outOpts.duration = outOpts.duration / 2;
      opts.duration = opts.duration / 2;
    }

    if (fadingElement) {
      // We still have some older version that is in the process of
      // fading out, so out first step is waiting for it to finish.
      finishPrevious = function () {
        return (0, _liquidFireTweenlite.finish)(fadingElement, 'fade-out');
      };
    } else {
      if ((0, _liquidFireTweenlite.isAnimating)(this.oldElement, 'fade-in')) {
        // if the previous view is partially faded in, scale its
        // fade-out duration appropriately.
        outOpts = { duration: (0, _liquidFireTweenlite.timeSpent)(this.oldElement, 'fade-in') };
      }
      (0, _liquidFireTweenlite.stop)(this.oldElement);
      animateOut = function () {
        return (0, _liquidFireTweenlite.animate)(_this.oldElement, { opacity: 0 }, outOpts, 'fade-out');
      };
    }

    animateIn = function () {
      return (0, _liquidFireTweenlite.animate)(_this.newElement, { opacity: opts.maxOpacity || 1 }, opts, 'fade-in');
    };

    return new _liquidFireTweenlite.TransitionPromise({
      finishPrevious: finishPrevious,
      animateOut: animateOut,
      animateIn: animateIn,
      parallel: false
    });
  }

  function findFadingElement(context) {
    for (var i = 0; i < context.older.length; i++) {
      var entry = context.older[i];
      if ((0, _liquidFireTweenlite.isAnimating)(entry.element, 'fade-out')) {
        return entry.element;
      }
    }
    if ((0, _liquidFireTweenlite.isAnimating)(context.oldElement, 'fade-out')) {
      return context.oldElement;
    }
  }
});