define('ember-material-lite/components/mdl-tab', ['exports', 'ember', 'ember-material-lite/components/mdl-tabs', 'ember-composability/mixins/child-component-support', 'ember-material-lite/templates/components/mdl-tab'], function (exports, _ember, _emberMaterialLiteComponentsMdlTabs, _emberComposabilityMixinsChildComponentSupport, _emberMaterialLiteTemplatesComponentsMdlTab) {
  'use strict';

  var computed = _ember['default'].computed;
  var dasherize = _ember['default'].String.dasherize;
  var A = _ember['default'].A;

  exports['default'] = _ember['default'].Component.extend(_emberComposabilityMixinsChildComponentSupport['default'], {
    layout: _emberMaterialLiteTemplatesComponentsMdlTab['default'],
    _parentComponentTypes: new A([_emberMaterialLiteComponentsMdlTabs['default']]),
    classNames: ['mdl-tabs__panel'],
    classNameBindings: ['isActive:is-active'],
    isActive: false,
    dasherizedTitle: computed('title', function () {
      return dasherize(this.get('title'));
    })
  });
});