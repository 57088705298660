define('ember-material-lite/mixins/shadow-support', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var computed = _ember['default'].computed;
  var Mixin = _ember['default'].Mixin;

  exports['default'] = Mixin.create({
    classNameBindings: ['_shadowClassString'],
    shadowDepth: 2,
    _shadowClassString: computed('shadowDepth', function () {
      return 'mdl-shadow--' + this.get('shadowDepth') + 'dp';
    })
  });
});