define('liquid-fire-tweenlite/transitions/fly-to', ['exports', 'liquid-fire-tweenlite', 'ember'], function (exports, _liquidFireTweenlite, _ember) {
  'use strict';

  exports['default'] = flyTo;

  var Promise = _ember['default'].RSVP.Promise;
  // jshint ignore:line

  function flyTo() {
    var _this = this;

    var opts = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];

    if (!this.newElement) {
      return _liquidFireTweenlite.TransitionPromise.resolve();
    } else if (!this.oldElement) {
      this.newElement.css({ visibility: '' });
      return _liquidFireTweenlite.TransitionPromise.resolve();
    }

    var oldOffset = this.oldElement.offset();
    var newOffset = this.newElement.offset();

    if (opts.movingSide === 'new') {
      var _ret = (function () {
        var motion = {
          x: [0, oldOffset.left - newOffset.left],
          y: [0, oldOffset.top - newOffset.top],
          outerWidth: [_this.newElement.outerWidth(), _this.oldElement.outerWidth()],
          outerHeight: [_this.newElement.outerHeight(), _this.oldElement.outerHeight()]
        };
        _this.oldElement.css({ visibility: 'hidden' });
        return {
          v: new _liquidFireTweenlite.TransitionPromise({
            animateIn: (function () {
              return (0, _liquidFireTweenlite.animate)(this.newElement, motion, opts);
            }).bind(_this)
          })
        };
      })();

      if (typeof _ret === 'object') return _ret.v;
    } else {
      var _ret2 = (function () {
        var motion = {
          x: newOffset.left - oldOffset.left,
          y: newOffset.top - oldOffset.top,
          outerWidth: _this.newElement.outerWidth(),
          outerHeight: _this.newElement.outerHeight()
        };
        _this.newElement.css({ visibility: 'hidden' });

        return {
          v: new _liquidFireTweenlite.TransitionPromise({
            animateOut: (function () {
              return (0, _liquidFireTweenlite.animate)(this.oldElement, motion, opts);
            }).bind(_this),
            animateIn: (function () {
              this.newElement.css({ visibility: '' });
              return Promise.resolve(true);
            }).bind(_this)
          })
        };
      })();

      if (typeof _ret2 === 'object') return _ret2.v;
    }
  }
});