define("ember-material-lite/templates/components/mdl-checkbox", ["exports"], function (exports) {
  "use strict";

  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type", "multiple-nodes"]
        },
        "revision": "Ember@2.4.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 3,
            "column": 0
          }
        },
        "moduleName": "modules/ember-material-lite/templates/components/mdl-checkbox.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("span");
        dom.setAttribute(el1, "class", "mdl-checkbox__label");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [2]);
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(element0, 0, 0);
        morphs[2] = dom.createMorphAt(element0, 1, 1);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "input", [], ["type", "checkbox", "id", ["subexpr", "@mut", [["get", "_inputId", ["loc", [null, [1, 27], [1, 35]]]]], [], []], "class", "mdl-checkbox__input", "checked", ["subexpr", "@mut", [["get", "value", ["loc", [null, [1, 72], [1, 77]]]]], [], []], "click", "onToggle", "disabled", ["subexpr", "@mut", [["get", "disabled", ["loc", [null, [1, 104], [1, 112]]]]], [], []]], ["loc", [null, [1, 0], [1, 114]]]], ["content", "text", ["loc", [null, [2, 34], [2, 42]]]], ["content", "yield", ["loc", [null, [2, 42], [2, 51]]]]],
      locals: [],
      templates: []
    };
  })());
});