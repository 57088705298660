define('ember-cli-g-maps/services/g-map', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports['default'] = _ember['default'].Service.extend({
    maps: (function () {
      var maps = _ember['default'].A([]);

      return {
        select: function select(name) {
          for (var i = 0, l = maps.length; i < l; i++) {
            if (maps[i].name === name) {
              return maps[i];
            }
          }
          return undefined;
        },

        add: function add(name, map) {
          if (typeof name !== 'string') {
            throw new Error('GMap name must be a string');
          }

          if (this.select(name)) {
            throw new Error('GMap name is taken, select a new GMap name');
          }

          var mapItem = { name: name };

          // Using accessor property to avoid calling warning via `service.add`
          Object.defineProperty(mapItem, 'onLoad', {
            get: function get() {
              return new _ember['default'].RSVP.Promise(function (resolve) {
                google.maps.event.addListenerOnce(map, 'idle', function () {
                  _ember['default'].Logger.warn('gMaps service onLoad has been deprecated, please use the component\'s `loaded` action instead.');
                  resolve();
                });
              });
            }
          });

          maps.pushObject(mapItem);

          return mapItem;
        },

        remove: function remove(name) {
          for (var i = 0, l = maps.length; i < l; i++) {
            if (maps[i].name === name) {
              maps.removeAt(i);
              return true;
            }
          }
          return false;
        }
      };
    })(),

    geocode: function geocode(options) {
      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        options.callback = function (result, status) {
          if (status === 'OK' || status === 'ZERO_RESULTS') {
            resolve(result);
          } else {
            var err = { status: status };

            // Add any available error_message
            if (result && result.error_message) {
              err.message = result.error_message;
            }

            reject(err);
          }
        };
        GMaps.prototype.geocode(options);
      });
    }
  });
});