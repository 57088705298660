define('liquid-fire-tweenlite/lib/scroll-to', ['exports', 'liquid-fire-tweenlite/lib/animate', 'ember'], function (exports, _liquidFireTweenliteLibAnimate, _ember) {
  'use strict';

  exports.scrollToY = scrollToY;
  exports.scrollToX = scrollToX;
  exports.scrollTo = scrollTo;

  var Promise = _ember['default'].RSVP.Promise;
  // jshint ignore:line

  function scrollToY(element, offset) {
    var options = arguments.length <= 2 || arguments[2] === undefined ? {} : arguments[2];

    options.duration = options.duration || 350;
    (0, _liquidFireTweenliteLibAnimate.stop)(element, 'scroll-y');
    return (0, _liquidFireTweenliteLibAnimate.animate)(element, { scrollTop: offset }, options, 'scroll-y');
  }

  function scrollToX(element, offset) {
    var options = arguments.length <= 2 || arguments[2] === undefined ? {} : arguments[2];

    options.duration = options.duration || 350;
    (0, _liquidFireTweenliteLibAnimate.stop)(element, 'scroll-x');
    return (0, _liquidFireTweenliteLibAnimate.animate)(element, { scrollLeft: offset }, options, 'scroll-x');
  }

  function scrollTo(element, offsetY, offsetX) {
    var options = arguments.length <= 3 || arguments[3] === undefined ? {} : arguments[3];

    return Promise.all([scrollToY(element, offsetY, options), scrollToX(element, offsetX, options)]);
  }
});