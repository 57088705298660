define('liquid-fire-tweenlite/transitions/default', ['exports', 'liquid-fire-tweenlite'], function (exports, _liquidFireTweenlite) {
  'use strict';

  exports['default'] = defaultTransition;

  function defaultTransition() {

    // Stop any currently running animation on oldView
    (0, _liquidFireTweenlite.stop)(this.oldElement);

    // This is what we run when no animation is asked for. It just sets
    // the newly-added element to visible (because we always start them
    // out invisible so that transitions can control their initial
    // appearance).
    if (this.newElement) {
      this.newElement.css({ visibility: '' });
    }

    return new _liquidFireTweenlite.TransitionPromise({});
  }
});