define('ember-cli-facebook-js-sdk/services/fb', ['exports', 'ember', 'ember-getowner-polyfill'], function (exports, _ember, _emberGetownerPolyfill) {
  'use strict';

  exports['default'] = _ember['default'].Service.extend({
    fbInitPromise: null,

    FBInit: function FBInit() {
      if (this.fbInitPromise) {
        return this.fbInitPromise;
      }

      var ENV = (0, _emberGetownerPolyfill['default'])(this)._lookupFactory('config:environment');

      if (ENV.FB && ENV.FB.skipInit) {
        this.fbInitPromise = _ember['default'].RSVP.Promise.resolve('skip init');
        return this.fbInitPromise;
      }

      var original = window.fbAsyncInit;
      var initSettings = ENV.FB;
      if (!initSettings || !initSettings.appId || !initSettings.version) {
        return _ember['default'].RSVP.reject('No settings for init');
      }

      this.fbInitPromise = new _ember['default'].RSVP.Promise(function (resolve) {
        window.fbAsyncInit = function () {
          window.FB.init(initSettings);
          _ember['default'].run(null, resolve);
        };
        _ember['default'].$.getScript('//connect.facebook.net/en_US/sdk.js', function () {
          // Do nothing here, wait for window.fbAsyncInit to be called.
        });
      }).then(function () {
        if (original) {
          window.fbAsyncInit = original;
          window.fbAsyncInit();
          window.fbAsyncInit.hasRun = true;
        }
      });

      return this.fbInitPromise;
    },

    setAccessToken: function setAccessToken(token) {
      this.accessToken = token;
      return token;
    },

    loginWith: function loginWith(token) {
      console.warn('DEPRECATED: please, use setAccessToken instead');
      this.setAccessToken(token);
    },

    api: function api(path) {
      var method = 'GET';
      var parameters = {};
      var arg;

      if (!path) {
        return _ember['default'].RSVP.reject('Please, provide a path for your request');
      }

      switch (arguments.length) {
        case 2:
          arg = arguments[1];
          if (typeof arg === 'string') {
            method = arg;
          } else {
            parameters = arg;
          }
          break;
        case 3:
          method = arguments[1];
          parameters = arguments[2];
      }

      parameters = _ember['default'].$.extend(parameters, { access_token: this.accessToken });

      return this.FBInit().then(function () {
        return new _ember['default'].RSVP.Promise(function (resolve, reject) {
          window.FB.api(path, method, parameters, function (response) {
            if (response.error) {
              _ember['default'].run(null, reject, response.error);
              return;
            }

            _ember['default'].run(null, resolve, response);
          });
        });
      });
    },

    ui: function ui(params) {
      return this.FBInit().then(function () {
        return new _ember['default'].RSVP.Promise(function (resolve, reject) {
          window.FB.ui(params, function (response) {
            if (response && !response.error_code) {
              _ember['default'].run(null, resolve, response);
              return;
            }

            _ember['default'].run(null, reject, response);
          });
        });
      });
    },

    // Facebook Login Methods

    getLoginStatus: function getLoginStatus(forceRequest) {
      return this.FBInit().then(function () {
        return new _ember['default'].RSVP.Promise(function (resolve) {
          window.FB.getLoginStatus(function (response) {
            _ember['default'].run(null, resolve, response);
          }, forceRequest);
        });
      });
    },

    login: function login(scope, authtype) {
      var service = this;
      return this.FBInit().then(function () {
        return new _ember['default'].RSVP.Promise(function (resolve, reject) {

          if (_ember['default'].isEmpty(authtype)) {
            window.FB.login(function (response) {
              if (response.authResponse) {
                service.accessToken = response.authResponse.accessToken;
                _ember['default'].run(null, resolve, response);
              } else {
                _ember['default'].run(null, reject, response);
              }
            }, { scope: scope });
          } else {
            window.FB.login(function (response) {
              if (response.authResponse) {
                service.accessToken = response.authResponse.accessToken;
                _ember['default'].run(null, resolve, response);
              } else {
                _ember['default'].run(null, reject, response);
              }
            }, { scope: scope, auth_type: authtype });
          }
        });
      });
    },

    logout: function logout() {
      return this.FBInit().then(function () {
        return new _ember['default'].RSVP.Promise(function (resolve) {
          window.FB.logout(function (response) {
            _ember['default'].run(null, resolve, response);
          });
        });
      });
    },

    getAuthResponse: function getAuthResponse() {
      return window.FB.getAuthResponse();
    }
  });
});