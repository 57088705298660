define('ember-material-lite/components/mdl-table-col', ['exports', 'ember', 'ember-material-lite/components/-base-child-component', 'ember-composability/mixins/child-component-support', 'ember-material-lite/components/mdl-table', 'ember-material-lite/templates/components/mdl-table-col'], function (exports, _ember, _emberMaterialLiteComponentsBaseChildComponent, _emberComposabilityMixinsChildComponentSupport, _emberMaterialLiteComponentsMdlTable, _emberMaterialLiteTemplatesComponentsMdlTableCol) {
  'use strict';

  var isEmpty = _ember['default'].isEmpty;
  var A = _ember['default'].A;

  exports['default'] = _emberMaterialLiteComponentsBaseChildComponent['default'].extend(_emberComposabilityMixinsChildComponentSupport['default'], {
    _parentComponentTypes: new A([_emberMaterialLiteComponentsMdlTable['default']]),
    tagName: 'td',
    layout: _emberMaterialLiteTemplatesComponentsMdlTableCol['default'],
    shouldRegisterToParent: function shouldRegisterToParent(parentComponent) {
      var childComponents = parentComponent.getComposableChildren();
      if (isEmpty(childComponents)) {
        return true;
      } else {
        var labels = parentComponent.getComposableChildren().map(function (x) {
          return x.label;
        });
        return labels.indexOf(this.get('label')) < 0;
      }
    }
  });
});