define('ember-material-lite/components/mdl-textfield', ['exports', 'ember-material-lite/components/-base-input-component', 'ember-material-lite/templates/components/mdl-textfield'], function (exports, _emberMaterialLiteComponentsBaseInputComponent, _emberMaterialLiteTemplatesComponentsMdlTextfield) {
  'use strict';

  exports['default'] = _emberMaterialLiteComponentsBaseInputComponent['default'].extend({
    pattern: null,
    isExpandable: false,
    expandableIcon: null,
    layout: _emberMaterialLiteTemplatesComponentsMdlTextfield['default'],
    classNameBindings: ['isExpandable:mdl-textfield--expandable'],
    beforeMdlInit: function beforeMdlInit() {
      this.$('label.mdl-button').attr('for', this.get('_inputId'));
    }
  });
});