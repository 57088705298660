define('ember-material-lite/components/mdl-menu-item', ['exports', 'ember', 'ember-composability/mixins/child-component-support', 'ember-material-lite/components/-base-child-component', 'ember-material-lite/components/mdl-menu', 'ember-material-lite/templates/components/mdl-menu-item'], function (exports, _ember, _emberComposabilityMixinsChildComponentSupport, _emberMaterialLiteComponentsBaseChildComponent, _emberMaterialLiteComponentsMdlMenu, _emberMaterialLiteTemplatesComponentsMdlMenuItem) {
  'use strict';

  exports['default'] = _emberMaterialLiteComponentsBaseChildComponent['default'].extend(_emberComposabilityMixinsChildComponentSupport['default'], {
    layout: _emberMaterialLiteTemplatesComponentsMdlMenuItem['default'],
    tagName: 'li',
    _parentComponentTypes: _ember['default'].A([_emberMaterialLiteComponentsMdlMenu['default']]),
    _childComponentClassStringDelimeter: '__',
    childComponentClassName: 'item',
    click: function click() {
      this.sendAction('action', this);
    }
  });
});