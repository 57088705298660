define('liquid-fire-tweenlite/lib/transition-promise', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var _slice = Array.prototype.slice;

  var _createClass = (function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];descriptor.enumerable = descriptor.enumerable || false;descriptor.configurable = true;if ('value' in descriptor) descriptor.writable = true;Object.defineProperty(target, descriptor.key, descriptor);
      }
    }return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);if (staticProps) defineProperties(Constructor, staticProps);return Constructor;
    };
  })();

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError('Cannot call a class as a function');
    }
  }

  var RSVP = _ember['default'].RSVP;
  var defer = // jshint ignore:line
  RSVP.defer;
  var Promise = RSVP.Promise;

  var TRANSITION_ID = 0;

  function returnResolved() {
    return Promise.resolve();
  }

  function wrapPromise(promise, deferred /* label */) {
    return promise.then(function (success) {
      deferred.resolve(success);
      return success;
    }, function (error) {
      deferred.reject(error);
      throw error;
    });
  }

  var TransitionPromise = (function () {
    function TransitionPromise(options) {
      var _this = this;

      _classCallCheck(this, TransitionPromise);

      this.id = '-tweenlite-transition-' + TRANSITION_ID++;
      this.parallel = options.parallel || false;
      options.finishPrevious = options.finishPrevious || returnResolved;
      options.animateOut = options.animateOut || returnResolved;
      options.animateIn = options.animateIn || returnResolved;

      this._animateOut = defer(this.id + '-out');
      this._animateIn = defer(this.id + '-in');
      this._firstStep = defer(this.id + '-start');

      this._promise = defer(this.id + '-main');

      wrapPromise(options.finishPrevious(), this._firstStep, 'first step').then(function (result) {
        if (_this.parallel) {
          return Promise.all([wrapPromise(options.animateOut(result), _this._animateOut, 'animate out'), wrapPromise(options.animateIn(result), _this._animateIn, 'animate in')]);
        }
        return wrapPromise(options.animateOut(result), _this._animateOut, 'animate out').then(function () {
          return wrapPromise(options.animateIn(result), _this._animateIn, 'animate in');
        });
      }).then(this._promise.resolve, this._promise.reject);
    }

    _createClass(TransitionPromise, [{
      key: 'firstStep',
      value: function firstStep() {
        return this._firstStep.promise;
      }
    }, {
      key: 'animateIn',
      value: function animateIn() {
        return this._animateIn.promise;
      }
    }, {
      key: 'animateOut',
      value: function animateOut() {
        return this._animateOut.promise;
      }
    }, {
      key: 'then',
      value: function then() {
        var _promise$promise$then;

        return (_promise$promise$then = this._promise.promise.then).call.apply(_promise$promise$then, [this._promise.promise].concat(_slice.call(arguments)));
      }
    }, {
      key: 'finally',
      value: function _finally() {
        var _promise$promise$finally;

        return (_promise$promise$finally = this._promise.promise['finally']).call.apply(_promise$promise$finally, [this._promise.promise].concat(_slice.call(arguments)));
      }
    }, {
      key: 'catch',
      value: function _catch() {
        var _promise$promise$catch;

        return (_promise$promise$catch = this._promise.promise['catch']).call.apply(_promise$promise$catch, [this._promise.promise].concat(_slice.call(arguments)));
      }
    }], [{
      key: 'resolve',
      value: function resolve() {
        return new TransitionPromise({});
      }
    }]);

    return TransitionPromise;
  })();

  exports['default'] = TransitionPromise;
});