define('ember-material-lite/mixins/registers-buttons', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var empty = _ember['default'].computed.empty;

  exports['default'] = _ember['default'].Mixin.create({
    _registersButtons: true,
    _buttons: null,
    _noButtons: empty('_buttons'),
    init: function init() {
      this._super.apply(this, arguments);
      this.set('_buttons', _ember['default'].A([]));
    },

    registerButton: function registerButton(button) {
      this.get('_buttons').addObject(button);
    }
  });
});