define('ember-material-lite/components/mdl-footer-section', ['exports', 'ember', 'ember-material-lite/components/mdl-mini-footer', 'ember-material-lite/components/mdl-mega-footer', 'ember-composability/mixins/child-component-support', 'ember-material-lite/components/-base-child-component', 'ember-material-lite/templates/components/mdl-footer-section'], function (exports, _ember, _emberMaterialLiteComponentsMdlMiniFooter, _emberMaterialLiteComponentsMdlMegaFooter, _emberComposabilityMixinsChildComponentSupport, _emberMaterialLiteComponentsBaseChildComponent, _emberMaterialLiteTemplatesComponentsMdlFooterSection) {
  'use strict';

  var computed = _ember['default'].computed;

  exports['default'] = _emberMaterialLiteComponentsBaseChildComponent['default'].extend(_emberComposabilityMixinsChildComponentSupport['default'], {
    _parentComponentTypes: _ember['default'].A([_emberMaterialLiteComponentsMdlMegaFooter['default'], _emberMaterialLiteComponentsMdlMiniFooter['default']]),
    layout: _emberMaterialLiteTemplatesComponentsMdlFooterSection['default'],
    align: 'middle',
    childComponentClassName: computed('align', function () {
      return this.get('align') + '-section';
    })
  });
});