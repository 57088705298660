define('liquid-fire-tweenlite/lib/tween', ['exports', 'tweenlite', 'ember', 'liquid-fire-tweenlite/lib/animate'], function (exports, _tweenlite, _ember, _liquidFireTweenliteLibAnimate) {
  'use strict';

  exports['default'] = tween;

  var copy = _ember['default'].copy;
  var isArray = _ember['default'].isArray;
  var defer = _ember['default'].RSVP.defer;

  var TWEEN_DEFAULTS = {
    duration: 350,
    delay: 0
  };

  var TWEEN_ID = 0;
  function tween($element) {
    var properties = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];
    var options = arguments.length <= 2 || arguments[2] === undefined ? {} : arguments[2];
    var label = arguments.length <= 3 || arguments[3] === undefined ? '' : arguments[3];

    var element = (0, _liquidFireTweenliteLibAnimate.getElement)($element);
    var opts = normalizeOptions(copy(properties, true), copy(options, true));
    var transition_id = '-lf-tweenlite:' + label + ':' + TWEEN_ID++;
    var deferred = defer(transition_id);
    var Tween = undefined;

    opts.onComplete = function () {
      deferred.resolve();
    };

    if (opts.fromVars) {
      var fromOpts = opts.fromVars;
      delete opts.fromVars;
      Tween = _tweenlite['default'].fromTo(element, opts.duration, { css: fromOpts }, opts);
    } else {
      Tween = _tweenlite['default'].to(element, opts.duration, opts);
    }

    Tween.transition_id = transition_id;
    Tween.transition_label = label;
    Tween._deferred = deferred;

    return deferred.promise;
  }

  function normalizeOptions(properties, options) {
    var opts = Object.assign({}, TWEEN_DEFAULTS, options);
    normalizeCSS(opts, properties);

    opts.duration = opts.duration / 1000;
    opts.delay = opts.delay / 1000;

    return opts;
  }

  function normalizeCSS(opts, css) {
    var fromVars = {};
    var hasFromVar = false;

    Object.keys(css).forEach(function (key) {
      if (isArray(css[key])) {
        fromVars[key] = css[key][0];
        css[key] = css[key][1];
        hasFromVar = true;
      }
    });

    if (hasFromVar) {
      opts.fromVars = fromVars;
    }

    // By default, we clear the element's `display`
    // and `visibility` properties at the start of animation. Our
    // animated divs are all initially rendered with `display:none`
    // and `visibility:hidden` to prevent a flash of before-animated
    // content.
    if (typeof css.display === 'undefined') {
      css.display = '';
    }
    if (typeof css.visibility === 'undefined') {
      css.visibility = '';
    }

    if (hasFromVar) {
      fromVars.display = css.display;
      fromVars.visibility = css.visibility;
    }

    opts.css = css;
  }
});