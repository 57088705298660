define('ember-material-lite/components/mdl-switch', ['exports', 'ember-material-lite/components/-base-toggle-component', 'ember-material-lite/templates/components/mdl-switch'], function (exports, _emberMaterialLiteComponentsBaseToggleComponent, _emberMaterialLiteTemplatesComponentsMdlSwitch) {
  'use strict';

  exports['default'] = _emberMaterialLiteComponentsBaseToggleComponent['default'].extend({
    primaryClassName: 'switch',
    layout: _emberMaterialLiteTemplatesComponentsMdlSwitch['default'],
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var mdlswitch = new window.MaterialSwitch(this.get('element'));
      this.set('_mdlComponent', mdlswitch);
    }
  });
});