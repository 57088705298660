define('ember-material-lite/components/mdl-radio', ['exports', 'ember', 'ember-material-lite/components/-base-toggle-component', 'ember-material-lite/templates/components/mdl-radio'], function (exports, _ember, _emberMaterialLiteComponentsBaseToggleComponent, _emberMaterialLiteTemplatesComponentsMdlRadio) {
  'use strict';

  var oneWay = _ember['default'].computed.oneWay;

  exports['default'] = _emberMaterialLiteComponentsBaseToggleComponent['default'].extend({
    primaryClassName: 'radio',
    layout: _emberMaterialLiteTemplatesComponentsMdlRadio['default'],
    checked: false,
    name: oneWay('_defaultName'),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var mdlradio = new window.MaterialRadio(this.get('element'));
      this.element.MaterialRadio = mdlradio;
      this.set('_mdlComponent', mdlradio);
    },

    _defaultName: 'default'
  });
});