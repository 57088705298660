define('liquid-fire-tweenlite/lib/animate', ['exports', 'ember', 'tweenlite', 'liquid-fire-tweenlite/lib/tween', 'jquery'], function (exports, _ember, _tweenlite, _liquidFireTweenliteLibTween, _jquery) {
  'use strict';

  exports.getElement = getElement;
  exports.animate = animate;
  exports.isAnimating = isAnimating;
  exports.getTween = getTween;
  exports.stop = stop;
  exports.finish = finish;
  exports.timeSpent = timeSpent;

  var Promise // jshint ignore:line
  = _ember['default'].RSVP.Promise;

  function getElement($element) {
    return $element && ($element instanceof _jquery['default'] || $element.constructor.prototype.jquery) ? $element.get(0) : $element;
  }

  function animate(element, properties /*, options, label*/) {
    if (!element || !properties) {
      return Promise.resolve();
    }

    return _liquidFireTweenliteLibTween['default'].apply(undefined, arguments);
  }

  function isAnimating($element, label) {
    return getTween($element, label);
  }

  function getTween($element, label) {
    if (!$element) {
      return false;
    }
    var element = getElement($element);
    var tweens = _tweenlite['default'].getTweensOf(element);
    for (var i = 0; i < tweens.length; i++) {
      if (tweens[i].label === label) {
        return tweens[i];
      }
    }
    return false;
  }

  function stop($element, label) {
    var tween = getTween($element, label);
    if (tween) {
      tween.kill();
      tween._deferred.reject();
    }
  }

  function finish($element, label) {
    var tween = getTween($element, label);
    return tween ? tween._deferred.promise : Promise.resolve();
  }

  function timeSpent($element, label) {
    var tween = getTween($element, label);
    return tween ? tween.progress() * tween._duration * 1000 : 0;
  }
});