define('ember-material-lite/initializers/material-lite-extensions', ['exports', 'ember-material-lite/extensions/tooltip'], function (exports, _emberMaterialLiteExtensionsTooltip) {
  'use strict';

  exports.initialize = initialize;

  function initialize() /* container, application */{
    // application.inject('route', 'foo', 'service:foo');
  }

  exports['default'] = {
    name: 'material-lite-extensions',
    initialize: initialize
  };
});