define('liquid-fire-tweenlite/transitions/scale', ['exports', 'liquid-fire-tweenlite'], function (exports, _liquidFireTweenlite) {
  'use strict';

  exports['default'] = scale;

  function scale() {
    var opts = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];

    return new _liquidFireTweenlite.TransitionPromise({
      animateOut: (function () {
        return (0, _liquidFireTweenlite.animate)(this.oldElement, { scale: [1, 0.2] }, opts, 'scale--out');
      }).bind(this),
      animateIn: (function () {
        return (0, _liquidFireTweenlite.animate)(this.newElement, { scale: [0.2, 1] }, opts, 'scale--in');
      }).bind(this)
    });
  }
});