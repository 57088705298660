define('liquid-fire-tweenlite/transitions/cross-fade', ['exports', 'liquid-fire-tweenlite'], function (exports, _liquidFireTweenlite) {
  'use strict';

  exports['default'] = crossFade;

  function crossFade() {
    var opts = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];

    (0, _liquidFireTweenlite.stop)(this.oldElement);

    return new _liquidFireTweenlite.TransitionPromise({
      animateOut: (function () {
        return (0, _liquidFireTweenlite.animate)(this.oldElement, { opacity: 0 }, opts);
      }).bind(this),
      animateIn: (function () {
        return (0, _liquidFireTweenlite.animate)(this.newElement, { opacity: opts.maxOpacity || 1 }, opts);
      }).bind(this),
      parallel: true
    });
  }
});