define('ember-material-lite/components/mdl-nav', ['exports', 'ember', 'ember-composability/mixins/parent-component-support', 'ember-material-lite/components/-base-toplevel-component', 'ember-material-lite/templates/components/mdl-nav'], function (exports, _ember, _emberComposabilityMixinsParentComponentSupport, _emberMaterialLiteComponentsBaseToplevelComponent, _emberMaterialLiteTemplatesComponentsMdlNav) {
  'use strict';

  var computed = _ember['default'].computed;
  var oneWay = _ember['default'].computed.oneWay;

  exports['default'] = _emberMaterialLiteComponentsBaseToplevelComponent['default'].extend(_emberComposabilityMixinsParentComponentSupport['default'], {
    primaryClassName: 'layout',
    composableChildrenDebounceTime: 1,
    classNameBindings: ['fixedHeader:mdl-layout--fixed-header:mdl-layout--overlay-drawer-button', 'fixedDrawer:mdl-layout--fixed-drawer'],
    layout: _emberMaterialLiteTemplatesComponentsMdlNav['default'],
    title: null,
    mobileTitle: oneWay('title'),
    fixedHeader: false,
    fixedDrawer: false,
    transparent: false,
    waterfallMenu: false,
    scrollHeader: false,
    includeHeader: true,
    includeHeaderLinks: true,
    includeDrawer: true,
    includeDrawerTitle: true,
    _mdlComponent: null,
    closeDrawerOnItemClick: false,

    _drawerNavItems: computed('composableChildren.[]', 'composableChildren.@each.inDrawer', function () {
      return _ember['default'].A(this.get('composableChildren').filter(function (x) {
        return x.inDrawer;
      }));
    }),

    _headerNavItems: computed('composableChildren.[]', 'composableChildren.@each.inHeader', function () {
      return _ember['default'].A(this.get('composableChildren').filter(function (x) {
        return x.inHeader;
      }));
    }),

    _headerClassString: computed('waterfallMenu', function () {
      var classes = ['mdl-layout__header'];
      if (this.get('waterfallMenu')) {
        classes.push('mdl-layout__header--waterfall');
      }
      if (this.get('scrollHeader')) {
        classes.push('mdl-layout__header--scroll');
      }
      if (this.get('transparent')) {
        classes.push('mdl-layout__header--transparent');
      }
      return classes.join(' ');
    }),

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);
      var mdlnav = new window.MaterialLayout(this.get('element'));
      this.set('_mdlComponent', mdlnav);

      this.$('nav.mdl-navigation').on('click', function (jqEvt) {
        if (_this.get('closeDrawerOnItemClick') && jqEvt.target.className.indexOf('mdl-navigation__link') >= 0 && _this.$(jqEvt.target).closest('nav.mdl-navigation').closest('.mdl-layout__drawer').hasClass('is-visible')) {
          console.log('closing');
          _ember['default'].run.next(function () {
            var _mdlComponent = _this.get('_mdlComponent');
            _mdlComponent.drawer_.classList.remove(_mdlComponent.CssClasses_.IS_DRAWER_OPEN);
            _mdlComponent.obfuscator_.classList.remove(_mdlComponent.CssClasses_.IS_DRAWER_OPEN);
            // this.get('_mdlComponent').drawerToggleHandler_();
          });
        }
      });
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.$('nav.mdl-navigation').off('click');
    }
  });
});