define('ember-material-lite/components/mdl-checkbox', ['exports', 'ember-material-lite/components/-base-toggle-component', 'ember-material-lite/templates/components/mdl-checkbox'], function (exports, _emberMaterialLiteComponentsBaseToggleComponent, _emberMaterialLiteTemplatesComponentsMdlCheckbox) {
  'use strict';

  exports['default'] = _emberMaterialLiteComponentsBaseToggleComponent['default'].extend({
    primaryClassName: 'checkbox',
    layout: _emberMaterialLiteTemplatesComponentsMdlCheckbox['default'],
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var mdlcheckbox = new window.MaterialCheckbox(this.get('element'));
      this.set('_mdlComponent', mdlcheckbox);
    }
  });
});