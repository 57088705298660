define('ember-material-lite/components/mdl-mega-footer', ['exports', 'ember-composability/mixins/parent-component-support', 'ember-material-lite/components/-base-toplevel-component', 'ember-material-lite/templates/components/mdl-mega-footer'], function (exports, _emberComposabilityMixinsParentComponentSupport, _emberMaterialLiteComponentsBaseToplevelComponent, _emberMaterialLiteTemplatesComponentsMdlMegaFooter) {
  // import Ember from 'ember';
  'use strict';

  exports['default'] = _emberMaterialLiteComponentsBaseToplevelComponent['default'].extend(_emberComposabilityMixinsParentComponentSupport['default'], {
    primaryClassName: 'mega-footer',
    _jsClass: false,
    tagName: 'footer',
    layout: _emberMaterialLiteTemplatesComponentsMdlMegaFooter['default']
  });
});