define('ember-material-lite/mixins/click-action-support', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports['default'] = _ember['default'].Mixin.create({
    click: function click() {
      this.sendAction('action', this);
      this.sendAction('on-click', this);
    },
    change: function change() {
      this.sendAction('on-change', this);
    }
  });
});