define('liquid-fire-tweenlite/transitions/flex-grow', ['exports', 'liquid-fire-tweenlite'], function (exports, _liquidFireTweenlite) {
  'use strict';

  exports['default'] = flexGrow;

  function flexGrow(opts) {
    (0, _liquidFireTweenlite.stop)(this.oldElement);
    return new _liquidFireTweenlite.TransitionPromise({
      animateOut: (function () {
        return (0, _liquidFireTweenlite.animate)(this.oldElement, { 'flex-grow': 0 }, opts);
      }).bind(this),
      animateIn: (function () {
        return (0, _liquidFireTweenlite.animate)(this.newElement, { 'flex-grow': [1, 0] }, opts);
      }).bind(this)
    });
  }
});