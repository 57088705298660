define('liquid-fire-tweenlite/transitions/move-over', ['exports', 'liquid-fire-tweenlite', 'ember'], function (exports, _liquidFireTweenlite, _ember) {
  'use strict';

  exports['default'] = moveOver;

  var Promise // jshint ignore:line
  = _ember['default'].RSVP.Promise;

  function moveOver(dimension, direction) {
    var _this = this;

    var opts = arguments.length <= 2 || arguments[2] === undefined ? {} : arguments[2];

    var finishPrevious = function finishPrevious() {
      var firstStep = undefined;

      if ((0, _liquidFireTweenlite.isAnimating)(_this.oldElement, 'moving-in')) {
        firstStep = (0, _liquidFireTweenlite.finish)(_this.oldElement, 'moving-in');
      } else {
        (0, _liquidFireTweenlite.stop)(_this.oldElement);
        firstStep = Promise.resolve();
      }

      return firstStep.then(function () {
        var oldParams = {};
        var newParams = {};
        var property = undefined;
        var measure = undefined;

        if (dimension.toLowerCase() === 'x') {
          property = 'x'; //'translateX';
          measure = 'width';
        } else {
          property = 'y'; // 'translateY';
          measure = 'height';
        }

        var bigger = biggestSize(_this, measure);

        oldParams[property] = bigger * direction + 'px';
        newParams[property] = [-1 * bigger * direction + 'px', '0px'];

        return {
          oldParams: oldParams,
          newParams: newParams
        };
      });
    };

    var animateOut = function animateOut(params) {
      return (0, _liquidFireTweenlite.animate)(_this.oldElement, params.oldParams, opts, 'move-over--out');
    };

    var animateIn = function animateIn(params) {
      return (0, _liquidFireTweenlite.animate)(_this.newElement, params.newParams, opts, 'move-over--in');
    };

    return new _liquidFireTweenlite.TransitionPromise({
      finishPrevious: finishPrevious,
      animateIn: animateIn,
      animateOut: animateOut,
      parallel: true
    });
  }

  function biggestSize(context, dimension) {
    var sizes = [];
    if (context.newElement) {
      sizes.push(parseInt(context.newElement.css(dimension), 10));
      sizes.push(parseInt(context.newElement.parent().css(dimension), 10));
    }
    if (context.oldElement) {
      sizes.push(parseInt(context.oldElement.css(dimension), 10));
      sizes.push(parseInt(context.oldElement.parent().css(dimension), 10));
    }
    return Math.max.apply(null, sizes);
  }
});