define('ember-material-lite/components/mdl-spinner', ['exports', 'ember-material-lite/components/-base-toplevel-component', 'ember-material-lite/templates/components/mdl-spinner'], function (exports, _emberMaterialLiteComponentsBaseToplevelComponent, _emberMaterialLiteTemplatesComponentsMdlSpinner) {
  // import Ember from 'ember';
  'use strict';

  exports['default'] = _emberMaterialLiteComponentsBaseToplevelComponent['default'].extend({
    primaryClassName: 'spinner',
    classNameBindings: ['isActive:is-active', 'isSingleColor:mdl-spinner--single-color'],
    _mdlComponent: null,
    isActive: true,
    isSingleColor: false,
    layout: _emberMaterialLiteTemplatesComponentsMdlSpinner['default'],
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var mdlspinner = new window.MaterialSpinner(this.get('element'));
      this.set('_mdlComponent', mdlspinner);
    }
  });
});