define('ember-material-lite/components/mdl-tabs', ['exports', 'ember-material-lite/components/-base-toplevel-component', 'ember-material-lite/mixins/ripple-support', 'ember-composability/mixins/parent-component-support', 'ember-material-lite/templates/components/mdl-tabs'], function (exports, _emberMaterialLiteComponentsBaseToplevelComponent, _emberMaterialLiteMixinsRippleSupport, _emberComposabilityMixinsParentComponentSupport, _emberMaterialLiteTemplatesComponentsMdlTabs) {
  'use strict';

  var _slicedToArray = (function () {
    function sliceIterator(arr, i) {
      var _arr = [];var _n = true;var _d = false;var _e = undefined;try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;_e = err;
      } finally {
        try {
          if (!_n && _i['return']) _i['return']();
        } finally {
          if (_d) throw _e;
        }
      }return _arr;
    }return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError('Invalid attempt to destructure non-iterable instance');
      }
    };
  })();

  // import Ember from 'ember';
  exports['default'] = _emberMaterialLiteComponentsBaseToplevelComponent['default'].extend(_emberComposabilityMixinsParentComponentSupport['default'], _emberMaterialLiteMixinsRippleSupport['default'], {
    parentClassName: 'tabs',
    layout: _emberMaterialLiteTemplatesComponentsMdlTabs['default'],
    classNames: ['mdl-tabs', 'mdl-js-tabs'],
    active: null,
    _mdlComponent: null,
    composableChildrenDebounceTime: 1,
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);
      var mdltabs = new window.MaterialTabs(this.get('element'));
      this.set('_mdlComponent', mdltabs);

      var _get$filter = this.get('composableChildren').filter(function (x) {
        return x.title === _this.get('active');
      });

      var _get$filter2 = _slicedToArray(_get$filter, 1);

      var activeTab = _get$filter2[0];

      if (activeTab) {
        activeTab.set('isActive', true);
      }
    },

    actions: {
      tabClicked: function tabClicked(tab) {
        var _this2 = this;

        var _get$filter3 = this.get('composableChildren').filter(function (x) {
          return x.title === _this2.get('active');
        });

        var _get$filter32 = _slicedToArray(_get$filter3, 1);

        var activeTab = _get$filter32[0];

        if (activeTab) {
          activeTab.set('isActive', false);
        }
        this.set('active', tab.get('title'));
        tab.set('isActive', true);
      }
    }
  });
});